import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"
import gamesPerStat from "../../js/gamesPerStat"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class FreeKicksPage extends React.Component {

  state = {
    showTopLevel: false,
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Free Kicks"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const mFKdata = this.props.data.allSheetMessiFreeKicks.edges
    const rFKdata = this.props.data.allSheetRonaldoFreeKicks.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / mdata.filter(d => d.node.competition === comp).map(d => d.node.freeKicks)[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / rdata.filter(d => d.node.competition === comp).map(d => d.node.freeKicks)[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    let [{
      freeKicks: mAllTimeFreeKicks,
      apps: mAllTimeApps,
      freeKickAttempts: mAllTimeFreeKickAttempts,
      fkc: mAllTimeFkc,
    }] = mdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)
  
    let [{
      freeKicks: rAllTimeFreeKicks,
      apps: rAllTimeApps,
      freeKickAttempts: rAllTimeFreeKickAttempts,
      fkc: rAllTimeFkc,
    }] = rdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)

    let [{
      freeKicks: mSince14FreeKicks,
      apps: mSince14Apps,
      freeKickAttempts: mSince14FreeKickAttempts,
      fkc: mSince14Fkc,
    }] = mdata.filter(d => d.node.competition === "League Since 14").map(d => d.node)
  
    let [{
      freeKicks: rSince14FreeKicks,
      apps: rSince14Apps,
      freeKickAttempts: rSince14FreeKickAttempts,
      fkc: rSince14Fkc,
    }] = rdata.filter(d => d.node.competition === "League Since 14").map(d => d.node)

    const since14Count = this.props.data.allSheetRonaldoClubStats.edges.filter(d => d.node.competition === "League" && d.node.freeKickAttempts !== "-").length


    return (

      <Layout>
        <SEO
          title={`Free Kicks - Messi vs Ronaldo Free Kick Goals and Conversion Rates`}
          description={`Messi and Ronaldo have both scored an impressive number of free kick goals in their career, but whose free kick numbers are the best?`}
          canonicalPath={`/detailed-stats/free-kicks/`}
        />

        <h1>Free Kick Goals <span className="sr-only">- Messi vs Ronaldo Free Kick Goals and Conversion Rates</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

        {comps.map(d => (
          <DetailedStatBars
            key={d.comp}
            data={this.props.data}
            competition={d.comp}
            title={d.title ? d.title : d.comp}
            topLevel={d.topLevel}
            showTopLevel={this.state.showTopLevel}
            playerTitleHidden={`${d.comp} ${pageTitle}`}
            statType={pageTitle}
            btnText={d.btnText}
            statView={['Totals', "Frequency"]}
            mSubStat={`scores a free kick every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} games`}
            rSubStat={`scores a free kick every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} games`}
            subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
          />
        ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo Free Kick Record</h2>

          <InfoBar />

          <InfoBar msg={`One of Ronaldo's ${Number(rAllTimeFreeKicks)} free kick goals was from an indirect free kick inside the box (where the free kick was rolled to Ronaldo to score) - if the lay-off is short enough it is considered by Opta as a free kick goal.`} />

          <p>Both Messi and Ronaldo have had somewhat curious journeys when it comes to their free kick records.</p>

          <p><strong className="text-ronaldo">Ronaldo</strong> quickly became known for scoring fairly outrageous free kick goals during his Manchester United career - in fact his first United goal was from a free kick. Some years later he introduced the famous “knuckleball” technique with his iconic strike against Portsmouth, and scored from an impossible distance against Arsenal in the Champions League.</p>
          
          <p><strong>He finished his Man Utd career with 5 free kick goals in each of his last 2 seasons, and then continued this dead ball form into his La Liga career, scoring 23 direct free kick goals in his first 5 seasons</strong>. However, that's when things began to turn, as he netted just 9 free kicks in his final 4 seasons at Madrid, and scored only <strong>1 free kick goal in 3 seasons at Juventus</strong>.</p>

          <p><strong className="text-messi">Messi's</strong> journey as a free kick specialist has been almost the exact opposite to Ronaldo's. Messi became Barcelona's all time leading goalscorer in March 2012, at which point he had scored just 5 free kick goals (with Ronaldo on 30). Then, everything changed for Messi, as <strong>he went on to score 7 in 2012 alone, before averaging 6 free kick goals each year for the next 7 years</strong>. He even managed to score 10 free kicks in a single calendar year in 2018, and then followed it up with another 8 in 2019.</p>

          <p>Messi's transformation into the ultimate free kick specialist in the second half of his career has been quite staggering to witness, whereas Ronaldo's increasing ineffectiveness compared to his early career form has been somewhat alarming.</p>

          <h3>The Numbers</h3>

          <p>In terms of the figures, these opposing journeys have merged almost in the same place, with <strong className="text-ronaldo">Ronaldo currently on {rAllTimeFreeKicks} career free kick goals</strong> and <strong className="text-messi">Messi now on {mAllTimeFreeKicks} all time free kick goals</strong>.</p>

          <p>To highlight how different their routes to these totals have been, at the end of 2013 Ronaldo was on 39 free kick goals, with Messi on just 14!</p>

          <p>Similarly, from 2017-2019, Messi scored 23 free kick goals to Ronaldo's 5; however, from 2009-2011, Ronaldo scored 21 direct free kick goals to Messi's 3.</p>

          <p>There is some rationale behind Messi's relatively late transformation: he wasn't the undisputed free kick taker at Barcelona during his earlier years at the club, playing second-fiddle to Ronaldinho and Xavi until it became clear he was the greater threat.</p>
          
          <p>Similarly, Ronaldo's decline in free kick goals can be partly explained by the fact he takes considerably fewer free kicks now than he used to earlier in his career. This reduction in free kick attempts can be attributed to Ronaldo having played with other free kick specialists in recent years such as Bale, Modric, Pjanic and Dybala - as well as Ronaldo often preferring to take up a position in the box where in previous years he might have gone for a direct attempt on goal.</p>

          <p>If we look at how often Messi and Ronaldo score free kicks, it's Messi that has the edge here. <strong className="text-messi">Messi scores a free kick every {gamesPerStat(mAllTimeFreeKicks, mAllTimeApps)} games</strong>, whereas <strong className="text-ronaldo">Ronaldo scores a free kick every {gamesPerStat(rAllTimeFreeKicks, rAllTimeApps)} games</strong>.</p>

          <p><strong>After surpassing Ronaldo's career total, the momentum is seemingly with Messi, and it seems increasingly likely that he will finish with the greater number of free kick goals and ultimately win the battle of the free kick specialists.</strong></p>

          <h2>Types of Free Kick Goals</h2>

          <p>Messi and Ronaldo have scored a very similar number of free kick goals, but the way they've scored them has been very different.</p>
          
          <p>Messi adopts a more traditional style, typically bending the ball into one of the corners from around 20-25 yards out, although he has scored a handful from 30+ yards. When right on the edge of the area he tends to hit the ball with force, arrowing the ball into one of the top corners. He's also scored several that he's aimed underneath the wall, playing his part in triggering the trend of teams opting to lay a player on the floor behind their wall.</p>

          <p>Cristiano Ronaldo on the other hand utilises the knuckleball technique, smashing the ball with his laces to avoid any spin, causing it to swerve and dip unpredictably towards the goal. This method is a lot more effective from distance than a ball struck with spin, as it gives the goalkeeper less time to adjust and the ball more time to snake through the air.</p>

          <p>Subsequently, Ronaldo's free kick goals have been scored from a much greater variety of positions than Messi's - many from seemingly impossible distances!</p>

          <h2>Messi Free Kick Conversion Rate <small>(League only)</small></h2>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
              <tr>
                <th>League Season</th>
                <th>Free Kick Attempts</th>
                <th>Free Kick Goals</th>
                <th>Free Kick Conversion Rate</th>
              </tr>
              </thead>
              <tbody>
              {this.props.data.allSheetMessiClubStats.edges.filter(d => d.node.competition === "League" && d.node.freeKickAttempts !== "-").map(d => d.node).map(
                d => (
                  <tr key={d.year+d.freeKickAttempts}>
                    <td>{d.year}</td>
                    <td>{d.freeKickAttempts}</td>
                    <td>{d.freeKicks}</td>
                    <td>{Number(d.fkc).toFixed(1) !== 'NaN' ? Number(d.fkc).toFixed(1) : '0'}%</td>
                  </tr>
                )
              )}
              <tr className="text-highlight font-bold">
                <td>2014 - present</td>
                <td>{mdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKickAttempts)}</td>
                <td>{mdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKicks)}</td>
                <td>{mdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => Number(d.node.fkc).toFixed(1))}%</td>
              </tr>
              <tr className="text-highlight font-bold">
                <td>Overall Totals</td>
                <td>{mdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKickAttempts)}</td>
                <td>{mdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKicks)}</td>
                <td>{mdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => Number(d.node.fkc).toFixed(1))}%</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h2>Ronaldo Free Kick Conversion Rate <small>(League only)</small></h2>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
              <tr>
                <th>League Season</th>
                <th>Free Kick Attempts</th>
                <th>Free Kick Goals</th>
                <th>Free Kick Conversion Rate</th>
              </tr>
              </thead>
              <tbody>
              {this.props.data.allSheetRonaldoClubStats.edges.filter(d => d.node.competition === "League" && d.node.freeKickAttempts !== "-").map(d => d.node).map(
                d => (
                  <tr key={d.year+d.freeKickAttempts}>
                    <td>{d.year}</td>
                    <td>{d.freeKickAttempts}</td>
                    <td>{d.freeKicks}</td>
                    <td>{Number(d.fkc).toFixed(1) !== 'NaN' ? Number(d.fkc).toFixed(1) : '0'}%</td>
                  </tr>
                )
              )}
              <tr className="text-highlight font-bold">
                <td>2014 - present</td>
                <td>{rdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKickAttempts)}</td>
                <td>{rdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKicks)}</td>
                <td>{rdata.filter(d => d.node.competition === "League Since 14" && d.node.freeKickAttempts !== "-").map(d => Number(d.node.fkc).toFixed(1))}%</td>
              </tr>
              <tr className="text-highlight font-bold">
                <td>Overall Totals</td>
                <td>{rdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKickAttempts)}</td>
                <td>{rdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => d.node.freeKicks)}</td>
                <td>{rdata.filter(d => d.node.competition === "All Time League" && d.node.freeKickAttempts !== "-").map(d => Number(d.node.fkc).toFixed(1))}%</td>
              </tr>
              </tbody>
            </table>
          </div>

          <p>Interestingly, Messi now takes significantly more free kicks than he used to, whilst Cristiano is actually taking considerably fewer shots from free kicks than earlier in his career.</p>

          <p>Before the 2014/15 season, Messi took 136 shots from direct free kicks in 10 La Liga seasons - an average of just 14 per season. Since then however, he has taken {mSince14FreeKickAttempts} shots at goal from free kicks, averaging {parseInt(Number(mSince14FreeKickAttempts) / Number(since14Count))} attempts per season.</p>

          <p>In contrast, Ronaldo hit 382 free kick shots in 11 seasons before 2014/15 in the league (not including his season at Sporting) - an average of 35 per season. But since then he's taken {rSince14FreeKickAttempts} shots at goal from free kicks, averaging {parseInt(Number(rSince14FreeKickAttempts) / Number(since14Count))} attempts per season. This is {Number(mSince14FreeKickAttempts)-Number(rSince14FreeKickAttempts)} fewer free kick shots than Messi has attempted in the same period. </p>

          <ul className="list-inside">
            <li><strong className="text-highlight">Before 2014/15</strong>: Messi <strong className="text-messi">136</strong> free kick shots (<strong className="text-messi">14</strong> per season on average)</li>
            <li><strong className="text-highlight">Before 2014/15</strong>: Ronaldo <strong className="text-ronaldo">382</strong> free kick shots (<strong className="text-ronaldo">35</strong> per season on average)</li>
            <li><strong className="text-highlight">After 2014/15</strong>: Messi <strong className="text-messi">{mSince14FreeKickAttempts}</strong> free kick shots (<strong className="text-messi">{parseInt(Number(mSince14FreeKickAttempts) / Number(since14Count))}</strong> per season on average)</li>
            <li><strong className="text-highlight">After 2014/15</strong>: Ronaldo <strong className="text-ronaldo">{rSince14FreeKickAttempts}</strong> free kick shots (<strong className="text-ronaldo">{parseInt(Number(rSince14FreeKickAttempts) / Number(since14Count))}</strong> per season on average)</li>
          </ul>

          <p>Despite this, Messi boasts a considerably better success rate, <strong className="text-messi">scoring {Number(mSince14Fkc).toFixed(1)}% of his free kick shots</strong> in the <strong className="text-highlight">league since 2014/15</strong>, with Ronaldo <strong className="text-ronaldo">scoring {Number(rSince14Fkc).toFixed(1)}% of his free kick attempts</strong> in the league since 2014/15.</p>

          <p><strong className="text-highlight">Before 2014/15</strong>, <strong className="text-messi">Messi scored 8.1% of his free kick attempts</strong> (11 from 136), whereas <strong className="text-ronaldo">Ronaldo scored 7.1% of his free kick shots</strong> (26 of 382). </p>

          <p><strong className="text-highlight">Overall</strong>, in all league seasons played (excluding Ronaldo's season at Sporting), <strong className="text-messi">Messi has converted {Number(mAllTimeFkc).toFixed(1)}% of his free kick shots</strong>, whereas <strong className="text-ronaldo">Ronaldo has converted {Number(rAllTimeFkc).toFixed(1)}% of his free kick shots</strong>.</p>

          <h2>Multiple Free Kicks Scored in a Game</h2>

          <p>Scoring from a free kick is a <em>relatively</em> rare thing; scoring two in a single game is almost unheard of. But both Messi and Ronaldo have achieved this feat on multiple occasions now.</p>

          <h4>Messi multiple free kicks in a game</h4>
          <ul>
            <li>11th Aug 2015 vs Sevilla (7th & 16th min) - UEFA Super Cup Final</li>
            <li>8th Dec 2018 vs Espanyol (17th & 65th min) - La Liga</li>
            <li>9th Nov 2019 vs Celta de Vigo (45th & 48th min) - La Liga</li>
          </ul>

          <h4>Ronaldo multiple free kicks in a game</h4>
          <ul>
            <li>15th Nov 2008 vs Stoke (3rd & 88th min) - Premier League</li>
            <li>15th Sep 2009 vs FC Zurich (27th & 89th min) - Champions League Group Stage</li>
            <li>15th May 2011 vs Villarreal (22nd & 90th min) - La Liga</li>
            <li>2nd April 2024 vs Abha (11th & 21st min) - Saudi Pro League</li>
          </ul>

          <h2>Complete List of Messi Free Kick Goals</h2>

          <p>A complete list of all Messi free kick goals for Barcelona, PSG and Argentina.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead className="text-left">
              <tr>
                <th>Date</th>
                <th>H/A</th>
                <th>Team</th>
                <th>Opponent</th>
                <th>Score</th>
                <th>Competition</th>
                <th>Score after FK</th>
              </tr>
              </thead>
              <tbody>
              {mFKdata.map(
                d => (
                  <tr key={d.node.date+d.node.opponent+d.node.scoreAfterFk}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.team}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                    <td>{d.node.scoreAfterFk}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          <h2>Complete List of Cristiano Ronaldo Free Kick Goals</h2>

          <p>A complete list of all Ronaldo free kick goals for Manchester United, Real Madrid, Juventus and Portugal.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead className="text-left">
              <tr>
                <th>Date</th>
                <th>H/A</th>
                <th>Team</th>
                <th>Opponent</th>
                <th>Score</th>
                <th>Competition</th>
                <th>Score after FK</th>
              </tr>
              </thead>
              <tbody>
              {rFKdata.map(
                d => (
                  <tr key={d.node.date+d.node.opponent+d.node.scoreAfterFk}>
                    <td>{d.node.date}</td>
                    <td>{d.node.homeAway}</td>
                    <td>{d.node.team}</td>
                    <td>{d.node.opponent}</td>
                    <td>{d.node.score}</td>
                    <td>{d.node.competition}</td>
                    <td>{d.node.scoreAfterFk}</td>
                  </tr>
                )
              )}
              </tbody>
            </table>
          </div>

          {/* <h2><span role="img" aria-label="fire">🔥</span> Messi Top 5 Free Kicks</h2>
          <p>These are obviously subjective, (and there are a lot to choose from!), but these are our picks for the top 5 free kicks of Lionel Messi's career.</p>

          <ol className="mt-0">
            <li>
              <h4>5) Real Madrid - Spanish Super Cup Final 29th August 2012</h4>
              <p>To give a goalkeeper like Casillas zero chance from that far out is impressive. To do it in El Clasico in a Spanish Super Cup final is special.</p>
              <iframe title="Messi free kick vs Real Madrid 2012" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/GSF7kTeb-zc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>4) Villarreal x2 - La Liga 8th January 2017 / 2nd April 2019</h4>
              <p>You're getting two for the price of one here. I know we said top 5, but these two efforts are basically the same goal against the same opposition, albeit two years apart! Top bins!</p>
              <iframe title="Messi free kick vs Villarreal 2017" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/ybpporLY_sQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>3) Colombia - World Cup Qualifier 15th November 2016</h4>
              <p>The accuracy is frightening.</p>
              <iframe title="Messi free kick vs Colombia 2016" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/9JJx_4fl4eA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>2) Liverpool - Champions League Semi-final 1st May 2019</h4>
              <p>An absolutely sensational effort from distance in a huge match against one of the best goalkeeprs in the world, and arguably the best team in the world at the time.</p>
              <iframe title="Messi free kick vs Liverpool 2019" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/-kG-CjHQEH8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>1) USA - Copa America 22nd June 2016</h4>
              <p>Aesthetically perfect, this free kick was nominated for the Puskas award and it's easy to see why. Stunning.</p>
              <iframe title="Messi free kick vs Copa America 2016" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/YNO9TdGHw5Q" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
          </ol>

          <h2><span role="img" aria-label="fire">🔥</span> Ronaldo Top 5 Free Kicks</h2>
          
          <ol>
            <li>
              <h4>5) Real Zaragoza - La Liga 12th December 2010</h4>
              <p>The goalkeeper <em>doesn't even move</em>! A superb knuckleball strike.</p>
              <iframe title="Ronaldo free kick vs Real Zaragoza 2010" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/dUsHh1WVGbA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>4) Atletico Madrid - La Liga 1st December 2012</h4>
              <p>Look how far out it is! And Ronaldo just nonchalently smashes it in, giving Courtois no chance.</p>
              <iframe title="Ronaldo free kick vs Atletico Madrid 2012" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/2Gn6rEMft3g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>3) Villarreal - La Liga 21st February 2010</h4>
              <p>This one is just insane. All that space to the keeper's right and he hammers it into the miniscule space on his left, hitting the inside of the post.</p>
              <iframe title="Ronaldo free kick vs Villarreal 2010" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/butc3vvOfAc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>2) Arsenal - Champions League Semi-final 5th May 2009</h4>
              <p><em>Maybe</em> it's generous putting this one at no.2 as the goalkeeping leaves a lot to be desired here. But the sheer distance on this warrants its spot for us. Unbelievable.</p>
              <iframe title="Ronaldo free kick vs Arsenal 2009" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/1zIWW8pVpyg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
            <li>
              <h4>1) Portsmouth - Premier League 30th January 2008</h4>
              <p>Iconic. This one truly astounded Premier League viewers around the world - how did he do it?! To hit it with such pace and yet such control... deadly.</p>
              <iframe title="Ronaldo free kick vs Portsmouth 2008" className="mt-4" width="560" height="315" src="https://www.youtube.com/embed/3hcfvObigQg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </li>
          </ol> */}

          <p>If you're looking for more free kick stats, contact us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we'll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          freeKickAttempts
          fkc
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          freeKickAttempts
          fkc
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          freeKickAttempts
          fkc
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          freeKickAttempts
          fkc
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          freeKicks
        }
      }
    }
    allSheetMessiFreeKicks {
      edges {
        node {
          date
          homeAway
          team
          score
          opponent
          competition
          scoreAfterFk
        }
      }
    }
    allSheetRonaldoFreeKicks {
      edges {
        node {
          date
          homeAway
          team
          score
          opponent
          competition
          scoreAfterFk
        }
      }
    }
  }
`

export default FreeKicksPage
